import Base from './base'

export default class Invoice extends Base {
  init () {
    this.id = null
    this.reservation_id = null
    this.name = null
    this.taxid = null
    this.address = null
    this.telephone = null
    this.bank = null
    this.account = null
    this.mail_name = null
    this.mail_number = null
    this.mail_address = null
    this.invoice_type = null
    this.invoice_category = 'Normal'

    return this
  }
}
